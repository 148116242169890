html, body, #root, .landing {
  height: 100%;
}

body {
  margin: 0;
}

.section-landing {
  background: url("./images/ant.jpg") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  color: black;
}

.content {
  margin-top: 40px;
  background: white;
}
